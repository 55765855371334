import { default as _650jahreJvYGoA1ysJMeta } from "/vercel/path0/app/pages/aktuelles/650jahre.vue?macro=true";
import { default as hochwasserzz2Mh63dN9Meta } from "/vercel/path0/app/pages/aktuelles/hochwasser.vue?macro=true";
import { default as projekteBRKSdqJdYqMeta } from "/vercel/path0/app/pages/aktuelles/projekte.vue?macro=true";
import { default as tischleinjU19Rl9v3UMeta } from "/vercel/path0/app/pages/aktuelles/tischlein.vue?macro=true";
import { default as datenschutzpI5kXs6pPZMeta } from "/vercel/path0/app/pages/datenschutz.vue?macro=true";
import { default as faqJTp1gkvZ2PMeta } from "/vercel/path0/app/pages/faq.vue?macro=true";
import { default as foerderantragwFsGjmtndgMeta } from "/vercel/path0/app/pages/foerderung/foerderantrag.vue?macro=true";
import { default as spendenundstiftenmbpp6CRZAHMeta } from "/vercel/path0/app/pages/helfen/spendenundstiften.vue?macro=true";
import { default as impressumGwrtgS1pGqMeta } from "/vercel/path0/app/pages/impressum.vue?macro=true";
import { default as indexiGEjlfgJ29Meta } from "/vercel/path0/app/pages/index.vue?macro=true";
import { default as kontaktRH9fJzEuYhMeta } from "/vercel/path0/app/pages/kontakt.vue?macro=true";
import { default as satzung8mNM1eQ63LMeta } from "/vercel/path0/app/pages/satzung.vue?macro=true";
import { default as spendenjnLUK0wDSOMeta } from "/vercel/path0/app/pages/spenden.vue?macro=true";
import { default as vergaberichtlinienqtWoLnLjt4Meta } from "/vercel/path0/app/pages/vergaberichtlinien.vue?macro=true";
import { default as gerd_45kaimerlWH7m8iXgjMeta } from "/vercel/path0/app/pages/wir/gerd-kaimer.vue?macro=true";
import { default as gremienQ3EwjUNokPMeta } from "/vercel/path0/app/pages/wir/gremien.vue?macro=true";
import { default as stiftungip7rHRe2PmMeta } from "/vercel/path0/app/pages/wir/stiftung.vue?macro=true";
export default [
  {
    name: "aktuelles-650jahre",
    path: "/aktuelles/650jahre",
    component: () => import("/vercel/path0/app/pages/aktuelles/650jahre.vue").then(m => m.default || m)
  },
  {
    name: "aktuelles-hochwasser",
    path: "/aktuelles/hochwasser",
    component: () => import("/vercel/path0/app/pages/aktuelles/hochwasser.vue").then(m => m.default || m)
  },
  {
    name: "aktuelles-projekte",
    path: "/aktuelles/projekte",
    component: () => import("/vercel/path0/app/pages/aktuelles/projekte.vue").then(m => m.default || m)
  },
  {
    name: "aktuelles-tischlein",
    path: "/aktuelles/tischlein",
    component: () => import("/vercel/path0/app/pages/aktuelles/tischlein.vue").then(m => m.default || m)
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    component: () => import("/vercel/path0/app/pages/datenschutz.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/vercel/path0/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "foerderung-foerderantrag",
    path: "/foerderung/foerderantrag",
    component: () => import("/vercel/path0/app/pages/foerderung/foerderantrag.vue").then(m => m.default || m)
  },
  {
    name: "helfen-spendenundstiften",
    path: "/helfen/spendenundstiften",
    component: () => import("/vercel/path0/app/pages/helfen/spendenundstiften.vue").then(m => m.default || m)
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/vercel/path0/app/pages/impressum.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "kontakt",
    path: "/kontakt",
    component: () => import("/vercel/path0/app/pages/kontakt.vue").then(m => m.default || m)
  },
  {
    name: "satzung",
    path: "/satzung",
    component: () => import("/vercel/path0/app/pages/satzung.vue").then(m => m.default || m)
  },
  {
    name: "spenden",
    path: "/spenden",
    component: () => import("/vercel/path0/app/pages/spenden.vue").then(m => m.default || m)
  },
  {
    name: "vergaberichtlinien",
    path: "/vergaberichtlinien",
    component: () => import("/vercel/path0/app/pages/vergaberichtlinien.vue").then(m => m.default || m)
  },
  {
    name: "wir-gerd-kaimer",
    path: "/wir/gerd-kaimer",
    component: () => import("/vercel/path0/app/pages/wir/gerd-kaimer.vue").then(m => m.default || m)
  },
  {
    name: "wir-gremien",
    path: "/wir/gremien",
    component: () => import("/vercel/path0/app/pages/wir/gremien.vue").then(m => m.default || m)
  },
  {
    name: "wir-stiftung",
    path: "/wir/stiftung",
    component: () => import("/vercel/path0/app/pages/wir/stiftung.vue").then(m => m.default || m)
  }
]